import { BadgeCheckIcon } from "@heroicons/react/solid"
import { Short, Talk, WPBtn } from "components"
import useBreakpoints from "hooks/useBreakPoints"
import Head from "next/head"
import Link from "next/link"
import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { userState } from "recoil/authState"
import { Autoplay, Navigation } from "swiper"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/navigation"
import { Swiper, SwiperSlide } from "swiper/react"
import classNames from "utils/classNames"
import api from "services/api"
import axios from "axios"
import { HomeSeo } from "components/SEO"

// const trainers = [
// 	{
// 		name: "Sanjay Kumar",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_19_24_04_066_af4548d955.png",
// 		companyLogo: "https://lucdn.letsupgrade.net/Haspr_3f13bad0ca.png",
// 		designation: "Co-Founder",
// 		company: "Haspr",
// 	},
// 	{
// 		name: "Satyam Tripathi",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_19_55_23_680_542c819a29.jpg",
// 		companyLogo: "https://lucdn.letsupgrade.net/ODIO_be93081e67.png",
// 		designation: "Machine Leaming Engineer",
// 		company: "ODIO",
// 	},
// 	{
// 		name: "Mohit Raj",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_16_16_44_501_2a5aa436c3.png",
// 		companyLogo: "https://lucdn.letsupgrade.net/Doneyhub_bfc8f8d237.png",
// 		designation: "Sr. Software developer",
// 		company: "Doyenhub",
// 	},
// 	{
// 		name: "Govindraj",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_15_59_06_890_b2d66b7bca.png",
// 		companyLogo: "https://lucdn.letsupgrade.net/Mphasis_LTD_04dbed54b7.png",
// 		designation: "Manager in data science",
// 		company: "Mphasis LTD",
// 	},
// 	{
// 		name: "Rohit Verma",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_16_10_35_975_90544083ae.png",
// 		companyLogo:
// 			"https://lucdn.letsupgrade.net/Morgan_Stanley_eab3cde7b1.png",
// 		designation: "Senior Manager",
// 		company: "Morgan Stanley",
// 	},
// 	{
// 		name: "Meghashyam T",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_19_19_47_861_afe4269a4b.png",
// 		companyLogo:
// 			"https://lucdn.letsupgrade.net/Judge_India_Solutions_2a7199c502.png",
// 		designation: "Lead Engineer",
// 		company: "Judge India Solutions",
// 	},
// 	{
// 		name: "Gaurav Lotey",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_16_14_50_500_be49775f0c.png",
// 		companyLogo: "https://lucdn.letsupgrade.net/fractal_2dff559fa8.png",
// 		designation: "Research And Development Engineer",
// 		company: "Fractal",
// 	},
// 	{
// 		name: "Kamal Nath",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_19_23_38_114_14e99e07a1.png",
// 		companyLogo:
// 			"https://lucdn.letsupgrade.net/Bank_of_America_955ce6673f.png",
// 		designation: "Software Engineer",
// 		company: "Bank of America",
// 	},
// 	{
// 		name: "Abhishek Yadav",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_16_12_13_718_d629baec23.png",
// 		companyLogo:
// 			"https://lucdn.letsupgrade.net/Consort_Digital_c4cfef875c.png",
// 		designation: "Software Engineer",
// 		company: "Consort Digita",
// 	},
// 	{
// 		name: "Gaurav Verma",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_15_57_18_145_d988e6243b.png",
// 		companyLogo: "https://lucdn.letsupgrade.net/CIPL_63025cbaa2.png",
// 		designation: "Data Scientist",
// 		company: "CIPL",
// 	},
// 	{
// 		name: "Kumar Sundram",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_16_00_43_715_1fdf8e276c.png",
// 		companyLogo: "https://lucdn.letsupgrade.net/HSBC_b056c7bf67.png",
// 		designation: "Manager",
// 		company: "HSBC",
// 	},
// 	{
// 		name: "Shivam Singh",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_15_58_04_819_affb2b3dcf.png",
// 		companyLogo:
// 			"https://lucdn.letsupgrade.net/IT_Skills_and_Solution_214b5a0ee3.png",
// 		designation: "Chief Al Engineer",
// 		company: "ITSS",
// 	},
// 	{
// 		name: "Lavish Jain",
// 		profileImage:
// 			"https://lucdn.letsupgrade.net/Picsart_22_12_29_16_15_39_918_eec6870f6e.png",
// 		companyLogo: "https://lucdn.letsupgrade.net/ATOS_152f88df8b.png",
// 		designation: "Full Stack developer",
// 		company: "ATOS",
// 	},
// ]

// const techs = [
// 	{
// 		title: "Frontend",
// 		skills: "HTML, CSS, Javascript, React",
// 		image: "https://lucdn.letsupgrade.net/Front_end_303610ad5c.jpg",
// 	},
// 	{
// 		title: "Backend",
// 		skills: "Javascript, Node.js and Express.js",
// 		image: "https://lucdn.letsupgrade.net/Back_end_3e0c41b38d.jpg",
// 	},
// 	{
// 		title: "Java Developer",
// 		skills: "Javascript, Node.js and Express.js",
// 		image: "https://lucdn.letsupgrade.net/Java_Developer_d868a2729a.jpg",
// 	},
// 	{
// 		title: "Python Developer",
// 		skills: "Python, Flask and Django",
// 		image: "https://lucdn.letsupgrade.net/Python_e7789a9696.jpg",
// 	},
// 	{
// 		title: "Data Structure & Algorithm",
// 		skills: "C++, C, Python",
// 		image: "https://lucdn.letsupgrade.net/Data_Structure_e9df18de04.jpg",
// 	},
// 	{
// 		title: "Cloud Platforms",
// 		skills: "AWS, GCP, Digital Ocean",
// 		image: "https://lucdn.letsupgrade.net/Cloud_f3ee3d2f67.jpg",
// 	},
// 	{
// 		title: "Software Testing",
// 		skills: "QA, Automation testing",
// 		image: "https://lucdn.letsupgrade.net/Software_cde67c261a.jpg",
// 	},
// 	{
// 		title: "SQL and NoSQL",
// 		skills: "MySQL, MongoDB, Firebase",
// 		image: "https://lucdn.letsupgrade.net/SQL_and_60ba6eb312.jpg",
// 	},
// 	{
// 		title: "Mobile App",
// 		skills: "Flutter, Kotlin, Swift",
// 		image: "https://lucdn.letsupgrade.net/Mobile_App_f035eb1d78.jpg",
// 	},
// 	{
// 		title: "Data Analyst",
// 		skills: "Python, Power Bi, Data Cleaning and Data Visualiazation",
// 		image: "https://lucdn.letsupgrade.net/Data_Analyst_090fc15b5f.jpg",
// 	},
// 	{
// 		title: "Data Scientist",
// 		skills: "Stats, Machine Learning, Deep learning and NLP",
// 		image: "https://lucdn.letsupgrade.net/Data_Scientist_1bcace3126.jpg",
// 	},
// ]

const shorts = [
	"KoGN9I2W8a8",
	"kORU6iXT9Fo",
	"jNrc1weINdU",
	"ICBQG49kMKw",
	"sWZVMkE5vVA",
	"d_ndJNBMp6g",
	"lQsbPek31eA",
	"ax5gFlFBUmA",
]

const talks = [
	{
		embedId: "wx4dbEwly6Q",
		title: "LetsUpgrade Trainer's Interview - Saurabh Sir (Full Stack Web Development Program)",
	},
	{
		embedId: "_YLNFDDSokM",
		title: "LetsUpgrade Trainer's Interview - Gopal Sir (Full-Stack AI, ML & DL Program)",
	},
	{
		embedId: "fAxaY4HYwd4",
		title: "LetsUpgrade Trainer's Interview - Mohit Sir (Full-Stack Python & Django Program)",
	},
	{
		embedId: "3BETQT0_9O0",
		title: "Sanjay Kumar - FS Web Development Trainer: Answering Most Asked Questions | LetsUpgrade",
	},
	{
		embedId: "dwpa4eyFZu8",
		title: "Abhishek Chaudhary - FS Web Development Trainer: Answering Most Asked Questions | LetsUpgrade",
	},
	{
		embedId: "e74jF27mLMU",
		title: "Preetish Priyadarshi - FS Web Development Trainer: Answering Most Asked Questions | LetsUpgrade",
	},
	{
		embedId: "WXkItuYSB9Y",
		title: "LetsUpgrade Trainer's Interview - Laxmi Mam (Full-Stack Python & Django Program)",
	},
	{
		embedId: "ivXaMg4izEE",
		title: "LetsUpgrade Trainer's Interview - Kamal Sir (Full-Stack Python & Django Program)",
	},
]

const CAROUSEL_BREAKPOINTS_CONFIG = (breakPoint, small) => {
	switch (breakPoint) {
		case "xs": {
			return {
				slidesPerView: small ? 2 : 1,
				spaceBetween: 20,
			}
			break
		}
		case "sm":
		case "md": {
			return {
				slidesPerView: small ? 3 : 2,
				spaceBetween: 40,
			}
			break
		}
		case "lg": {
			return {
				slidesPerView: small ? 4 : 3,
				spaceBetween: 50,
			}
			break
		}
	}
}
const Icon = icon => {
	switch (icon) {
		case "📜":
			return "https://lucdn.letsupgrade.net/scroll_1f4dc_12796dfb09.png"
		case "☎️":
			return "https://lucdn.letsupgrade.net/black_telephone_260e_fded62c761.png"
		case "🏆":
			return "https://lucdn.letsupgrade.net/trophy_1f3c6_48a70a0c1b.png"
		case "🤔":
			return "https://lucdn.letsupgrade.net/Instagram_name_logo_transparent_PNG_ee3354708c.png"
		case "📝":
			return "https://lucdn.letsupgrade.net/memo_1f4dd_2575d12b75.png"
		case "🔎":
			return "https://lucdn.letsupgrade.net/right_pointing_magnifying_glass_1f50e_886ef32571.png"
		case "🤩":
			return "https://lucdn.letsupgrade.net/grinning_face_with_star_eyes_1f929_74760f511a.png"
		case "💰":
			return "https://lucdn.letsupgrade.net/money_bag_1f4b0_724f691c70.png"
		case "🚀":
			return "https://lucdn.letsupgrade.net/rocket_1f680_18c31c38a5.png"
		case "✅":
			return "https://lucdn.letsupgrade.net/white_heavy_check_mark_2705_279da79cd8.png"
		case "💵":
			return "https://lucdn.letsupgrade.net/banknote_with_dollar_sign_1f4b5_a57978d006.png"
		case "⏲️":
			return "https://lucdn.letsupgrade.net/timer_clock_23f2_8c81f63040.png"
	}
}

const ContainerDots = () => (
	<div
		className="absolute inset-0 hidden overflow-hidden rounded-3xl md:block"
		aria-hidden="true"
	>
		<svg
			className="absolute bottom-full left-full translate-y-1/3 -translate-x-2/3 transform xl:bottom-auto xl:top-0 xl:translate-y-0"
			width={404}
			height={384}
			fill="none"
			viewBox="0 0 404 384"
			aria-hidden="true"
		>
			<defs>
				<pattern
					id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
					x={0}
					y={0}
					width={20}
					height={20}
					patternUnits="userSpaceOnUse"
				>
					<rect
						x={0}
						y={0}
						width={4}
						height={4}
						className="text-gray-200"
						fill="currentColor"
					/>
				</pattern>
			</defs>
			<rect
				width={404}
				height={384}
				fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
			/>
		</svg>
		<svg
			className="absolute top-full -translate-y-1/3 -translate-x-1/3 transform xl:-translate-y-1/2"
			width={404}
			height={384}
			fill="none"
			viewBox="0 0 404 384"
			aria-hidden="true"
		>
			<defs>
				<pattern
					id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
					x={0}
					y={0}
					width={20}
					height={20}
					patternUnits="userSpaceOnUse"
				>
					<rect
						x={0}
						y={0}
						width={4}
						height={4}
						className="text-gray-200"
						fill="currentColor"
					/>
				</pattern>
			</defs>
			<rect
				width={404}
				height={384}
				fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
			/>
		</svg>
	</div>
)

const ApplyNow = ({ className = "" }) => {
	const user = useRecoilValue(userState)
	return (
		<Link href={user ? "/console" : "/register"}>
			<a
				className={classNames(
					"block w-full rounded-md border py-3 px-5 text-center text-base font-medium shadow-md active:shadow-sm sm:inline-block sm:w-auto",
					className
				)}
			>
				{user ? "Go To Dashboard" : "Apply Now"}
			</a>
		</Link>
	)
}

const Home = () => {
	const { active: activeBreakPoint } = useBreakpoints()
	const [techs, setTechs] = useState()
	const [trainers, setTrainers] = useState()

	const getTechs = () => {
		axios.get("https://cms.letsupgrade.net/techs").then(resp => {
			setTechs(resp.data)
		})
	}

	const getTrainers = () => {
		axios
			.get("https://cms.letsupgrade.net/trainers?_sort=srno")
			.then(resp => {
				setTrainers(resp.data)
			})
	}

	const getData = () => {
		getTechs()
		getTrainers()
	}

	useEffect(getData, [])

	return (
		<>
			<HomeSeo />
			<div className="relative mx-auto max-w-3xl px-4 pb-8 pt-24 text-white sm:px-6 md:max-w-7xl md:px-8">
				{/* Heading */}
				<div className="flex w-full flex-col items-center justify-center py-10 md:py-32">
					<div className="flex flex-col items-center space-y-16">
						<div className="flex max-w-screen-sm flex-col items-center space-y-4">
							<h1 className="flex flex-col items-center space-y-4">
								<span className="block whitespace-nowrap">
									<span className="gradientFont !font-serif text-[40px] italic leading-none md:text-7xl xl:text-9xl">
										Trainerpreneur
									</span>
									{/* <span className="mr-2 font-serif text-xl italic">
										&trade;
									</span> */}
								</span>
								<span className="!mt-0 text-3xl md:mt-auto md:text-6xl">
									Program
								</span>
							</h1>
							<span className="text-center text-base md:text-lg">
								Earn up to ₹ 60,000 a month teaching tech skills
								online with LetsUpgrade programs. Join 250+
								working professionals.
							</span>
						</div>
						<ApplyNow className="transition-all hover:bg-white hover:text-black" />
					</div>
				</div>
				{/* Trainers */}
				<div className="my-16 flex w-full flex-col items-center justify-center space-y-8 overflow-hidden pb-4">
					<span className="max-w-2xl text-center text-2xl md:text-3xl">
						Trainer who earned more than{" "}
						<span className="whitespace-nowrap">₹ 1 lakh</span>{" "}
						through{" "}
						<span className="whitespace-nowrap">
							<span className="gradientFont !font-serif italic">
								Trainerpreneur
							</span>
							{/* <span className="mr-1.5 text-xl font-serif italic">
							&trade;
						</span> */}{" "}
							Program
							<img
								src={Icon("😍")}
								alt=""
								className="ml-2 inline-block h-6"
							/>
						</span>
					</span>
					{trainers ? (
						<Swiper
							className="w-full"
							loop
							modules={[Autoplay]}
							grabCursor
							{...CAROUSEL_BREAKPOINTS_CONFIG(
								activeBreakPoint,
								true
							)}
							autoplay={{
								delay: 5000,
							}}
						>
							{trainers.map(trainer => (
								<SwiperSlide key={trainer.name}>
									<div className="flex select-text flex-col overflow-hidden rounded-lg border border-gray-800 p-2 shadow-md transition-all hover:border-gray-200">
										<div className="relative h-56 rounded-lg">
											<img
												src={trainer.profileImage.url}
												alt=""
												className="h-full w-full rounded-md object-cover"
											/>
											{/* <div className="absolute top-0 right-0 rounded-md bg-slate-100 px-2">
										<img
											src={trainer.companyLogo}
											alt=""
											className="aspect-video h-9 object-cover"
										/>
									</div> */}
										</div>
										<div className="flex flex-col px-1 py-3 text-sm">
											<h2 className="text-lg font-bold leading-5">
												{trainer.name}
											</h2>
											<span className="mb-3 text-gray-500">
												@{trainer.name.toLowerCase()}
											</span>
											<span className="text-xs">
												{trainer.designation},{" "}
												{trainer.company}
											</span>
										</div>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					) : null}
				</div>
				{/* Why */}
				<div className="my-16 pb-4 md:grid md:grid-cols-12">
					<div className="relative z-10 md:col-span-3 md:col-start-10 md:row-start-1 md:bg-transparent md:py-16">
						<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 md:max-w-none md:p-0">
							<div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 md:aspect-w-1 flex justify-center">
								<img
									className="mb-0 hidden w-full rounded-3xl object-cover object-center shadow-2xl md:block"
									src="https://lucdn.letsupgrade.net/Trainerpreneur_2_773ca0ac08.jpg"
									alt=""
								/>
								<img
									className="-mb-16 block w-full rounded-3xl object-cover object-center shadow-2xl md:hidden"
									src="https://lucdn.letsupgrade.net/Trainerpreneur_1_793cf58531.jpg"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div className="relative rounded-3xl bg-white md:col-span-11 md:col-start-1 md:row-start-1 md:grid md:grid-cols-10 md:items-center">
						<ContainerDots />
						<div className="relative mx-auto max-w-md space-y-6 py-12 px-4 pt-20 text-black sm:max-w-3xl md:col-span-6 md:col-start-2 md:max-w-none md:p-0 md:pt-0">
							<h2
								className="text-3xl font-bold tracking-tight "
								id="join-heading"
							>
								<span className="gradientFont">
									Why should You teach
								</span>{" "}
								at LetsUpgrade
								<img
									src={Icon("🤔")}
									alt=""
									className="ml-2 inline-block h-6"
								/>
								?
							</h2>
							<div className="grid-row-3 !mt-4 grid gap-2 md:!mt-12 md:grid-cols-3 md:gap-4">
								<span className="flex flex-col justify-between space-y-2 py-2">
									<span className="flex items-center space-x-2 md:flex-col md:items-start md:space-x-0 md:space-y-2">
										<img
											src={Icon("📜")}
											alt=""
											className="h-12 w-12 flex-shrink-0 rounded-md p-2 text-2xl"
										/>
										<span className="font-bold">
											Letter of contribution
										</span>
									</span>
									<span>
										You will receive a letter of
										contribution to the LetsUpgrade.
									</span>
								</span>
								<span className="flex flex-col justify-between space-y-2 py-2">
									<span className="flex items-center space-x-2 md:flex-col md:items-start md:space-x-0 md:space-y-2">
										<img
											src={Icon("💰")}
											alt=""
											className="h-12 w-12 flex-shrink-0 rounded-md p-2 text-2xl"
										/>
										<span className="font-bold">
											Earn upto ₹ 2500/- Hour
										</span>
									</span>
									<span>
										Based on your profile, you can earn upto
										INR
										<span className="whitespace-nowrap">
											₹ 2500/-
										</span>{" "}
										Per hour.
									</span>
								</span>
								<span className="flex flex-col justify-between space-y-2 py-2">
									<span className="flex items-center space-x-2 md:flex-col md:items-start md:space-x-0 md:space-y-2">
										<img
											src={Icon("🏆")}
											alt=""
											className="h-12 w-12 flex-shrink-0 rounded-md p-2 text-2xl"
										/>
										<span className="font-bold">
											Get Rewards & Incentives
										</span>
									</span>
									<span>
										Every month, we reward your efforts by
										giving you incentives.
									</span>
								</span>
							</div>
							<ApplyNow className="bg-gray-900 text-white hover:bg-gray-800" />
						</div>
					</div>
				</div>
				<div className="my-16 flex w-full flex-col items-center justify-center space-y-8 overflow-hidden pb-4">
					<span className="max-w-xl text-center text-3xl font-bold tracking-tight">
						We are{" "}
						<span className="gradientFont">looking to hire</span>{" "}
						these <span className="gradientFont">skill</span> set
						<img
							src={Icon("🚀")}
							alt=""
							className="ml-2 inline-block h-6"
						/>{" "}
						?
					</span>
					<span className="!mt-2 max-w-2xl text-center">
						If you think you can teach any of these skills in super
						engaging way, do apply now !!
					</span>
					{techs ? (
						<Swiper
							className="w-full"
							loop
							modules={[Autoplay]}
							grabCursor
							{...CAROUSEL_BREAKPOINTS_CONFIG(
								activeBreakPoint,
								true
							)}
							autoplay={{
								delay: 5000,
							}}
						>
							{techs.map(tech => (
								<SwiperSlide key={tech.name}>
									<div className="flex select-text flex-col space-y-2 overflow-hidden rounded-lg border border-gray-800 p-2 shadow-md transition-all hover:border-gray-200">
										<div className="relative rounded-lg">
											<img
												src={tech.image.url}
												alt={tech.name}
												className="h-full w-full rounded-md object-contain md:object-cover"
											/>
										</div>
										<div className="flex flex-col space-y-2 px-1 py-3 text-sm">
											<h2 className="text-lg font-bold leading-5">
												{tech.name}
											</h2>
											<span className="mb-3 text-gray-500">
												{tech.description}
											</span>
										</div>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					) : null}
				</div>
				{/* How */}
				<div className="my-16 pb-4 md:grid md:grid-cols-12">
					<div className="relative z-10 md:col-span-3 md:col-start-1 md:row-start-1 md:bg-transparent md:py-16">
						<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 md:max-w-none md:p-0">
							<div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 md:aspect-w-1 flex justify-center">
								<img
									className="mb-0 hidden w-full rounded-3xl object-cover object-center shadow-2xl md:block"
									src="https://lucdn.letsupgrade.net/panel_2_1437c32152.jpg"
									alt=""
								/>
								<img
									className="-mb-16 block w-full rounded-3xl object-cover object-center shadow-2xl md:hidden"
									src="https://lucdn.letsupgrade.net/panel_1_e9a9efb88c.jpg"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div className="relative rounded-3xl bg-white md:col-span-11 md:col-start-3 md:row-start-1 md:grid md:grid-cols-10 md:items-center">
						<ContainerDots />
						<div className="relative mx-auto max-w-md space-y-6 py-12 px-4 pt-20 text-black sm:max-w-3xl sm:py-16 sm:px-6 md:col-span-6 md:col-start-3 md:max-w-none md:p-0 md:pt-0">
							<h2
								className="text-3xl font-bold tracking-tight"
								id="join-heading"
							>
								How can you become a{" "}
								<span className="gradientFont">
									verified trainer
								</span>{" "}
								at LetsUpgrade
								<BadgeCheckIcon className="mx-2 inline-block h-8 w-8 text-green-500" />
								?
							</h2>
							<ol>
								<li className="flex items-center space-x-2 py-2">
									<img
										src={Icon("📝")}
										alt=""
										className="h-9 w-9 flex-shrink-0 rounded-md p-2"
									/>
									<span>Apply with basic details</span>
								</li>
								<li className="flex items-center space-x-2 py-2">
									<img
										src={Icon("🔎")}
										alt=""
										className="h-9 w-9 flex-shrink-0 rounded-md p-2"
									/>
									<span>
										Your profile will be evaluated by
										LetsUpgrade Team
									</span>
								</li>
								<li className="flex items-center space-x-2 py-2">
									<img
										src={Icon("☎️")}
										alt=""
										className="h-9 w-9 flex-shrink-0 rounded-md p-2"
									/>
									<span>
										Our senior team members will take your
										phone call interview
									</span>
								</li>
								<li className="flex items-center space-x-2 py-2">
									<img
										src={Icon("✅")}
										alt=""
										className="h-9 w-9 flex-shrink-0 rounded-md p-2"
									/>
									<span>
										You are now all set to take sessions on
										LetsUpgrade once your profile is
										verified
									</span>
								</li>
							</ol>
							<ApplyNow className="bg-gray-900 text-white hover:bg-gray-800" />
						</div>
					</div>
				</div>
				{/* Where */}
				<div className="my-16 flex w-full flex-col items-center justify-center space-y-8 pb-4 md:overflow-hidden">
					<div className="relative flex flex-col items-center space-y-6">
						<span className="text-left text-3xl font-extrabold md:text-center">
							Which{" "}
							<span className="gradientFont font-semibold">
								platform
							</span>{" "}
							will be used for{" "}
							<span className="gradientFont font-semibold">
								teaching students
							</span>
							<img
								src={Icon("🤔")}
								alt=""
								className="ml-2 inline-block h-6"
							/>{" "}
							?
						</span>
						<span className="text-left text-xl md:text-center">
							Live lectures will be delivered using LetsUpgrade{" "}
							<span className="font-semibold text-purple-500">
								LisA
								{/* <svg class="link-svgline">
								<use xlink:href="#svg_line"></use>
							</svg> */}
							</span>{" "}
							platform
						</span>
						<div className="relative hidden flex-col items-stretch justify-start md:flex">
							<span className="h-10 w-px self-center bg-gradient-to-b from-black to-lu-500 pr-px" />
						</div>
						<span className="max-w-xl text-left md:text-center">
							You won't have to go to multiple locations,
							everything will be in one location to track your
							Trainerpreneur Program -
						</span>
					</div>
					<div className="w-full p-6 md:overflow-hidden">
						<div className="relative hidden flex-col items-stretch justify-start md:flex">
							<span className="h-24 w-px self-center bg-gradient-to-b from-black to-lu-500 pr-px" />
						</div>
						<div className="relative mb-8 flex w-full flex-col space-y-4 md:mb-0 md:flex-row md:space-y-0">
							<div className="order-last mt-4 flex flex-1 md:order-none md:mt-0">
								<div className="md:mt-14 md:-translate-y-[30%] md:pr-16">
									<img
										className="w-full rounded-3xl object-cover object-center shadow-2xl md:-mb-16"
										src="https://lucdn.letsupgrade.net/class_schedule_914ede306f.jpg"
										alt=""
									/>
								</div>
							</div>
							<div className="absolute inset-y-0 -left-5 top-0 order-2 flex h-full w-0 flex-col md:relative md:left-0 md:order-none md:h-auto md:w-auto">
								<span className="bg-gradient-to-b from-black to-lu-500" />
								<span className="my-1 flex h-6 w-6 -translate-x-1/2 items-center justify-center rounded-full bg-lu-500 text-white shadow-[0_0_0_1.5px_#ff6600_inset] md:h-8 md:w-8 md:translate-x-0">
									1
								</span>
								<span className="h-full w-px flex-1 self-center bg-gradient-to-b from-black to-lu-500" />
								{/* <span className="w-px flex-1 self-center border-r border-dashed border-[#444]" /> */}
							</div>
							<div className="order-first !mt-0 flex flex-1 md:order-none md:mt-auto">
								<div className="mt-4 md:-mt-2 md:mb-6 md:pl-32 md:pr-12 md:pb-64">
									<h5 className="mb-2 text-2xl font-semibold tracking-tight">
										Your Class{" "}
										<span className="gradientFont">
											Schedule
										</span>
										<img
											src={Icon("⏲️")}
											alt=""
											className="ml-2 inline-block h-6"
										/>
									</h5>
									<ul className="list-inside list-disc">
										<li>
											See all your upcoming and previous
											classes
										</li>
										<li>
											At a glance, you can view classes
											for all cohorts
										</li>
										<li>
											Clearly define your time commitments
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="relative mb-8 flex w-full flex-col space-y-4 md:mb-0 md:flex-row md:space-y-0">
							<div className="order-first !mt-0 flex flex-1 md:order-none md:mt-auto">
								<div className="mt-4 md:-mt-2 md:mb-6 md:pl-16 md:pr-12 md:pb-64">
									<h5 className="mb-2 text-2xl font-semibold tracking-tight">
										Class{" "}
										<span className="gradientFont">
											Performance
										</span>
										<img
											src={Icon("🚀")}
											alt=""
											className="ml-2 inline-block h-6"
										/>
									</h5>
									<ul className="list-inside list-disc">
										<li>
											See if students are understanding
											your concepts
										</li>
										<li>
											Checkout your average class
											attendance
										</li>
										<li>
											Get all metrics of all classes
											delivered till date
										</li>
									</ul>
								</div>
							</div>
							<div className="absolute inset-y-0 -left-5 top-0 order-2 flex h-full w-0 flex-col md:relative md:left-0 md:order-none md:h-auto md:w-auto">
								<span className="bg-gradient-to-r from-black to-lu-500 " />
								<span className="my-1 flex h-6 w-6 -translate-x-1/2 items-center justify-center rounded-full bg-lu-500 text-white shadow-[0_0_0_1.5px_#ff6600_inset] md:h-8 md:w-8 md:translate-x-0">
									2
								</span>
								<span className="h-full w-px flex-1 self-center bg-gradient-to-b from-black to-lu-500 " />
							</div>
							<div className="order-first flex flex-1 md:order-none">
								<div className="md:mt-14 md:-translate-y-[30%] md:pl-16">
									<img
										className="w-full rounded-3xl object-cover object-center shadow-2xl"
										src="https://lucdn.letsupgrade.net/Profile_982acf6e09.jpg"
										alt=""
									/>
								</div>
							</div>
						</div>
						<div className="relative mb-8 flex w-full flex-col space-y-4 md:mb-0 md:flex-row md:space-y-0">
							<div className="order-last mt-4 flex flex-1 md:order-none md:mt-0">
								<div className="md:mt-14 md:-translate-y-[30%] md:pr-16">
									<img
										className="w-full rounded-3xl object-cover object-center shadow-2xl"
										src="https://lucdn.letsupgrade.net/Roster_67de06af26.jpg"
										alt=""
									/>
								</div>
							</div>
							<div className="absolute inset-y-0 -left-5 top-0 order-2 flex h-full w-0 flex-col md:relative md:left-0 md:order-none md:h-auto md:w-auto">
								<span className="bg-gradient-to-r from-black to-lu-500 " />
								<span className="my-1 flex h-6 w-6 -translate-x-1/2 items-center justify-center rounded-full bg-lu-500 text-white shadow-[0_0_0_1.5px_#ff6600_inset] md:h-8 md:w-8 md:translate-x-0">
									3
								</span>
								<span className="h-full w-px flex-1 self-center bg-gradient-to-b from-black to-lu-500 " />
							</div>
							<div className="order-first !mt-0 flex flex-1 md:order-none md:mt-auto">
								<div className="mt-4 md:-mt-2 md:mb-6 md:pl-32 md:pr-12 md:pb-64">
									<h5 className="mb-2 text-2xl font-semibold tracking-tight">
										<span className="gradientFont">
											Earnings
										</span>
										<img
											src={Icon("💵")}
											alt=""
											className="ml-2 inline-block h-6"
										/>
									</h5>
									<ul className="list-inside list-disc">
										<li>
											How much did you earn month by month
										</li>
										<li>
											How much amount is pending to be
											deposited in your account
										</li>
										<li>
											Download all your tax certificates
											from the same place
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="relative mb-8 flex w-full flex-col space-y-4 md:mb-0 md:flex-row md:space-y-0">
							<div className="order-first !mt-0 flex flex-1 md:order-none md:mt-auto">
								<div className="mt-4 md:-mt-2 md:mb-6 md:pl-16 md:pr-12 md:pb-64">
									<h5 className="mb-2 text-2xl font-semibold tracking-tight">
										Trainer{" "}
										<span className="gradientFont">
											Leaderboard
										</span>
										<img
											src={Icon("🤩")}
											alt=""
											className="ml-2 inline-block h-6"
										/>
									</h5>
									<ul className="list-inside list-disc">
										<li>
											Based on your performance, see your
											leaderboard
										</li>
										<li>
											Top the leaderboard to get
											incentives apart from your regular
											earnings
										</li>
									</ul>
								</div>
							</div>
							<div className="absolute inset-y-0 -left-5 top-0 order-2 flex h-full w-0 flex-col md:relative md:left-0 md:order-none md:h-auto md:w-auto">
								<span className="bg-gradient-to-r from-black to-lu-500 " />
								<span className="my-1 flex h-6 w-6 -translate-x-1/2 items-center justify-center rounded-full bg-lu-500 text-white shadow-[0_0_0_1.5px_#ff6600_inset] md:h-8 md:w-8 md:translate-x-0">
									4
								</span>
								<span className="h-full w-px flex-1 self-center bg-gradient-to-b from-black to-lu-500 " />
							</div>
							<div className="order-last flex flex-1 md:order-none">
								<div className="md:mt-14 md:-translate-y-[30%] md:pl-16">
									<img
										className="w-full rounded-3xl object-cover object-center shadow-2xl"
										src="https://lucdn.letsupgrade.net/Leaderboard_6b2cfd6bf2.jpg"
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
					<ApplyNow className="transition-all hover:bg-white hover:text-black" />
				</div>
				{/* Trainer of the Week & Month */}
				<div className="my-16 flex w-full flex-col items-center justify-center space-y-2 overflow-hidden pb-4">
					<span className="gradientFont max-w-xl text-center text-3xl font-semibold">
						Trainer of the Week & Month
						<img
							src={Icon("🚀")}
							alt=""
							className="ml-2 inline-block h-6"
						/>
					</span>
					<span className="text-center text-xl">
						See all our previous trainer of the month winners, from
						Trainerpreneur program
					</span>
					<Swiper
						className="!my-8 w-full"
						navigation
						loop
						grabCursor
						modules={[Navigation]}
						{...CAROUSEL_BREAKPOINTS_CONFIG(activeBreakPoint)}
					>
						{shorts.map(short => (
							<SwiperSlide key={short}>
								<Short short={short} />
							</SwiperSlide>
						))}
					</Swiper>
					<ApplyNow className="transition-all hover:bg-white hover:text-black" />
				</div>
				{/* Speakers */}
				<div className="mt-16 flex w-full flex-col items-center justify-center space-y-8 overflow-hidden pb-4">
					<span className="gradientFont max-w-xl text-center text-3xl font-semibold">
						Our Trainer Speaks
						<img
							src={Icon("🤩")}
							alt=""
							className="ml-2 inline-block h-6"
						/>
					</span>
					<span className="text-center text-xl">
						Trainer interviews from LetsUpgrade's Trainerpreneur
						program sharing their experience and guiding future
						developers
					</span>
					<Swiper
						className="w-full"
						navigation
						loop
						grabCursor
						modules={[Navigation]}
						{...CAROUSEL_BREAKPOINTS_CONFIG(activeBreakPoint)}
					>
						{talks.map(talk => (
							<SwiperSlide key={talk.embedId}>
								<Talk talk={talk} />
							</SwiperSlide>
						))}
					</Swiper>
					<ApplyNow className="transition-all hover:bg-white hover:text-black" />
				</div>
			</div>
		</>
	)
}

export default Home
